@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Halant", serif;
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url("Halant-Light.ttf") format("truetype");
    }

    @font-face {
        font-family: "Halant", serif;
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url("Halant-Regular.ttf") format("truetype");
    }

    @font-face {
        font-family: "Halant", serif;
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url("Halant-Medium.ttf") format("truetype");
    }

    @font-face {
        font-family: "Halant", serif;
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url("Halant-SemiBold.ttf") format("truetype");
    }

    @font-face {
        font-family: "Halant", serif;
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url("/fonts/Halant-Bold.ttf") format("truetype");
    }
}

@layer components {
    .form-item {
        @apply border border-white bg-white rounded-lg border-white p-4;
    }
    .form-item-selected {
        @apply border border-dark-green rounded-lg border-white p-4;
    }
    .form-ring-item {
        @apply border-gray-300 shadow-sm focus:border-light-green focus:ring focus:ring-light-green focus:ring-opacity-50 rounded-lg;
    }
    .form-error {
        @apply text-red-700;
    }
    .form-btn {
        @apply bg-light-green text-white rounded-full w-full h-10;
    }
    .flexplan-input-group {
        @apply w-12 flex items-center justify-center border-t border-l border-b rounded-l bg-gray-200;
    }

    .flexplan-button-group {
        @apply text-gray-500 bg-transparent border border-black font-bold uppercase text-xs px-4 py-2 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150;
    }

    /* select:not([value=""]), */
    input:not([value=""]) {
        border-color: #181818;
    }

    select:not([value=""]) {
        border-color: #d8dfe1;
        background-color: inherit;
    }
    select:hover {
        border-color: #181818;
        background-color: #f4f7f8;
    }

    input::placeholder {
        color: #767778;
    }

    h1,
    h2 {
        font-family: "Halant", serif;
    }

    h1 {
        font-weight: 600;
        font-size: 54px;
        line-height: 1;
    }
}
